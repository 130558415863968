import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'
import { Accordion, Card } from 'react-bootstrap'

import { siteMetadata } from '../../gatsby-config'
import Layout from 'components/layout'
import Meta from 'components/meta'
import Medemer from 'components/medemer'

class Terms extends React.Component {
  render() {
    const { location, data } = this.props

    return (
      <Layout location={location}>
        <Meta site={siteMetadata} title="About" />

        <div>
          <section className="jumbotron text-left">
            <div className="container">
              <h1 className="jumbotron-heading">Privacy Policy</h1>
              <p className="lead text-muted">&nbsp;</p>
            </div>
          </section>

          <div className="container marketing">
            <div className="row featurette">
              <div className="col-md-12">
                <p>
                  <em>(Last updated 14 August 2019) </em>
                </p>
                <p>
                  Medemer.et has been created by and is owned by Dr Abiy Ahmed.
                  We are committed to respecting the privacy and security of
                  your personal information in compliance with the General Data
                  Protection Regulation, the Privacy and Electronic
                  Communications Regulation and the Information Commissioner
                  Office’s guidelines. The following Privacy Policy describes
                  the information we collect via this website and how we use it.
                  By using this website, you consent to our Privacy policy.
                </p>
                <p>
                  If you have any questions or concerns relating to our privacy
                  policy or how we capture, store or use your personal
                  information, please contact us via our Contact Page or contact
                  our Data Protection Officer on info@medemer.et
                </p>
                <p>
                  <strong>Email contact</strong>
                </p>
                <p>
                  If you submit your e-mail address to us, for example through
                  our Contact Page, we will keep this on record in order to
                  contact you only regarding your enquiry and will delete it
                  once the matter is resolved unless you tell us otherwise. We
                  will never sell or rent your e-mail or other information you
                  have provided to us to third parties.
                </p>
                <p>
                  If you submit your email address to us for the express purpose
                  of receiving information and news about our work and/or
                  partnership opportunities (and this was clear on the
                  submission), this action is your explicit consent to receive
                  such information and we will use your email address to provide
                  you with the requested information.
                </p>
                <p>
                  You can always opt-out of hearing from us for any reason and
                  at any time by replying unsubscribe to your emails, emailing
                  us info@medemer.et or clicking unsubscribe in your emails.
                </p>
                <p>
                  <strong>Collecting other information</strong>
                </p>
                <p>
                  Like many websites, we gather information about how visitors
                  use our website. In general, you can visit many of our web
                  pages without telling us who you are or revealing any personal
                  information about yourself. We may track your visit for trends
                  and statistics, but you will remain anonymous unless you
                  actively submit your personal data to us through the website.
                  Once you choose to give us your personal information, you are
                  not anonymous to us. For more information, see "Cookies and
                  Pixel Tags" section below.
                </p>
                <p>
                  <strong>Sharing information</strong>
                </p>
                <p>
                  We will never provide your information to third parties,
                  agencies or companies for marketing or commercial purposes.
                </p>
                <p>
                  We will not share your personal information without your
                  explicit consent unless required to do so upon governmental
                  request, in response to a court order, when required by law
                  enforcement agencies, to enforce our website policies, or to
                  protect our or others' rights, property, or safety. We may
                  also share information with companies assisting in fraud
                  protection or investigation.
                </p>

                <p>
                  <strong>Modifying or removing your information</strong>
                </p>
                <p>
                  You have the right to withdraw any consent given with respect
                  to our use of your personal data. Furthermore, you have the
                  right to request the modification or deletion of your personal
                  data in the event it is incorrect or processed without your
                  consent.
                </p>
                <p>
                  To modify your personal data, or to completely opt-out of
                  Medemer using your personal data, please contact us via
                  info@medemer.et
                </p>

                <p>
                  <strong>Cookies and pixel tags</strong>
                </p>
                <p>
                  A cookie is a small removable data file that is stored by your
                  web browser on your computer. Cookies allow us to enhance and
                  personalise your online experience.
                </p>
                <p>
                  For example, we use cookies to: Recognise you when you return
                  to our website; Analyse site visitor behaviour
                </p>
                <p>
                  We also use pixel tags - tiny graphic images - to help us
                  analyse your online behaviour. Pixel tags also allow us to
                  send you email in a format you can read and let us know when
                  you have opened an email message from us.
                </p>
                <p>
                  We may use pixel tags to collect information about your visit,
                  including the pages you view, the links you click and other
                  actions taken in connection with our site. We also collect
                  certain standard information that your browser sends to every
                  website you visit, such as your IP address, browser type and
                  language, access times, and referring website addresses.
                </p>

                <p>
                  <strong>Children's privacy</strong>
                </p>
                <p>
                  We do not knowingly collect personal information from children
                  under the age of 13.
                </p>

                <p>
                  <strong>International users</strong>
                </p>
                <p>
                  This website is operated, in whole or in part, from Ethiopia.
                  By using this website, regardless of where you live in the
                  world, you consent to have your personal data transferred to
                  and processed in Ethiopia, and allow us to use and collect
                  your personal information in accordance with this Privacy
                  Policy.
                </p>

                <p>
                  <strong>Policy changes</strong>
                </p>
                <p>
                  We may change our Privacy Policy from time to time. We
                  encourage you to refer back to this policy on an ongoing basis
                  so that you understand our current Privacy Policy.
                </p>
                <p>This policy was last modified on 1 August 2019.</p>

                <p>
                  <strong>Questions and feedback</strong>
                </p>
                <p>
                  We welcome your questions, comments, and concerns about
                  privacy or any other aspect of our website. Please send us any
                  and all feedback pertaining to privacy to info@medemer.et
                </p>
                <p>
                  <strong>Terms of use</strong>
                </p>
                <p>
                  Please also visit our
                  <a href="/terms">
                    <strong>Terms and Conditions </strong>
                  </a>
                  section that explains other terms governing the use of our
                  website.
                </p>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

export default Terms
